import HeroSectionPhoto from "../../assets/pictures/Aside-Background.jpg";
import HeroSection from "../../components/views/HeroSection/HeroSection";
import PostPicture from "../../assets/pictures/Aside-Background.jpg";
import { Link, useParams } from "react-router-dom";
import { useState } from "react";
import "./Blog.scss";
import { Icon } from "@iconify/react/dist/iconify.js";

export default function Blog() {

    const { post } = useParams()
    const [categories, setCategories] = useState()

    const postList = [0, 1, 2, 3, 4, 5] 

    return (
        <section id="blog">
            <HeroSection picture={HeroSectionPhoto} words={["Blog"]} />

            <div className="blog-container" id="next">
                <div className="post-container">
                    {postList.map((item, i) => <BlogItem />)}
                </div>
            
                <div className="blog-navigation">
                    <div>
                        <h3>Search</h3>
                        <label className="search">
                            <input type="text" placeholder="Search..." required />
                            <button>
                                <Icon icon="gg:search" width="19" height="19" />
                            </button>
                        </label>
                    </div>

                    <div>
                        <h3>Categories</h3>
                        <ul>
                            <li className="selected">
                                <div className="arrows">
                                    <Icon icon="iconoir:nav-arrow-right" width="19" height="19" />
                                    <Icon icon="iconoir:nav-arrow-right" width="19" height="19" />
                                </div>
                                <span>News</span>
                            </li>
                            <li>
                                <span>Offers</span>
                            </li>
                            <li>
                                <span>Spa & Wellness</span>
                            </li>
                            <li>
                                <span>Events</span>
                            </li>
                            <li>
                                <span>Food & Drinks</span>
                            </li>
                        </ul>
                    </div>

                    <div>
                        <h3>Recent Posts</h3>
                        <div className="recent-post-container">
                            {postList.map((item, i) => <SmallBlogPost />)}
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}

function BlogItem() {
    return (
        <div className="post">
            <Link to={''} className="image" style={{ backgroundImage: `url(${PostPicture})` }} />

            <div className="text">
                <div className="category-title">
                    <Icon icon="ph:dot-outline" width="19" height="19" />
                    <span>Spa & Wellness</span>
                    <Icon icon="ph:dot-outline" width="19" height="19" />
                    <span>06 April 2023</span>
                </div>
                
                <Link to={''}  className="title font-cormorant">Why a Luxurious Hotel Stay is worth the investmnet</Link>
                
                <p>Quisque pretium fermentum quam, sit amet cursus ante sollicitudin vel.</p>
                
                <Link className="read-more" to={'/'}>
                    <span className="arrows">
                        <Icon icon="iconoir:nav-arrow-right" width="19" height="19" />
                        <Icon icon="iconoir:nav-arrow-right" width="19" height="19" />
                    </span>
                    <span className="text">Read More</span>
                    <span className="arrows">
                        <Icon icon="iconoir:nav-arrow-left" width="19" height="19" />
                        <Icon icon="iconoir:nav-arrow-left" width="19" height="19" />
                    </span>
                </Link>
            </div>
        </div>
    )
}

function SmallBlogPost() {
    return (
        <div className="small-blog">
            <Link to={''} className="image" style={{ backgroundImage: `url(${PostPicture})` }} />

            <div className="text">
                <Link to={''}  className="title font-cormorant">Why a Luxurious Hotel Stay is worth the investmnet</Link>
                
                <div className="category-title">
                    <Icon icon="ph:dot-outline" width="19" height="19" />
                    <span>06 April 2023</span>
                </div>
            </div>
        </div>
    )
}