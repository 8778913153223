import HeroSectionPhoto from "../../assets/pictures/Aside-Background.jpg";
import HeroSection from "../../components/views/HeroSection/HeroSection";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useState } from "react";
import "./Faq.scss";

export default function Faq() {

    const faqList = [
        {
            question: "Can we bring our pets?",
            answer: "Each of our rooms has a workspace along with free access to Wi-Fi. You can print documents at the reception or enjoy our free fax and scanning services. Each of our rooms also includes a power block on the desk, which has two outlets and two USB ports.",
        },
        {
            question: "Is there an elevator in the hotel?",
            answer: "Each of our rooms has a workspace along with free access to Wi-Fi. You can print documents at the reception or enjoy our free fax and scanning services. Each of our rooms also includes a power block on the desk, which has two outlets and two USB ports.",
        },
        {
            question: "What is the checkout time?",
            answer: "Each of our rooms has a workspace along with free access to Wi-Fi. You can print documents at the reception or enjoy our free fax and scanning services. Each of our rooms also includes a power block on the desk, which has two outlets and two USB ports.",
        },
        {
            question: "Can I keep my room after the standard check out time?",
            answer: "Each of our rooms has a workspace along with free access to Wi-Fi. You can print documents at the reception or enjoy our free fax and scanning services. Each of our rooms also includes a power block on the desk, which has two outlets and two USB ports.",
        },
        {
            question: "At what time can we check into our room?",
            answer: "Each of our rooms has a workspace along with free access to Wi-Fi. You can print documents at the reception or enjoy our free fax and scanning services. Each of our rooms also includes a power block on the desk, which has two outlets and two USB ports.",
        },
        {
            question: "Do your rooms include a workspace?",
            answer: "Each of our rooms has a workspace along with free access to Wi-Fi. You can print documents at the reception or enjoy our free fax and scanning services. Each of our rooms also includes a power block on the desk, which has two outlets and two USB ports.",
        },
    ]

    return (
        <section id="faq">
            <HeroSection picture={HeroSectionPhoto} words={["Faq"]} />

            <div className="content">
                <div>
                    <h3>General</h3>
                    <div className="question-container">
                        {faqList.map((item, i) => <QuestionContainer key={i} item={item} />)}
                    </div>
                </div>

                <div className="faq-navigation">
                    <h3>Search</h3>
                    <label className="search">
                        <input type="text" placeholder="Search..." required />
                        <button>
                            <Icon icon="gg:search" width="19" height="19" />
                        </button>
                    </label>
                </div>
            </div>
        </section>
    )
}

function QuestionContainer({ item }) {

    const [isOpen, setIsOpen] = useState(false)

    return (
        <div className="question">
            <div className="topbar" onClick={() => setIsOpen(!isOpen)}>
                <h3>{item.question}</h3>
                <Icon icon={isOpen ? "iconoir:nav-arrow-down" : "iconoir:nav-arrow-right"} width="20" height="20" />
            </div>

            <div className={`answer-container ${isOpen ? 'open' : ''}`}>
                <p>{item.answer}</p>
            </div>
        </div>
    )
}