import RoomPhoto from "../../assets/pictures/Showcase/Showcase.png";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useEffect, useState } from "react";
import "./Reservation.scss";

const minutes = 20;
const maxTime = minutes * 60000;

export default function Reservation() {
    const [overLimit, setOverLimit] = useState(false) 

    useEffect(() => {
        const timer = setTimeout(() => {
            setOverLimit(true);
        }, maxTime);

        return () => clearTimeout(timer);
    }, []);

    // return <Loader isFullSized={true} />

    if(overLimit) {
        return (
            <section className="reservation">
                <h1>Over Limite</h1>
            </section>
        )
    }

    return (
        <section className="reservation">
            <div className="single-form">
                <h1>reservation</h1>
            </div>

            <div className="summary">
                <div className="group">
                    <div className="row">
                        <div>
                            <p className="bigger">Wild Serenity Tunisia</p>

                            <div className="stars">
                                <Icon icon="tabler:star-filled" width="19" height="19" />
                                <Icon icon="tabler:star-filled" width="19" height="19" />
                                <Icon icon="tabler:star-filled" width="19" height="19" />
                                <Icon icon="tabler:star-filled" width="19" height="19" />
                                <Icon icon="tabler:star-filled" width="19" height="19" />
                            </div>
                        </div>

                        <img src={RoomPhoto} alt="Room Showcase" />
                    </div>
                </div>

                <div className="group">
                    <div className="row">
                        <p>
                            <Icon icon="hugeicons:calendar-check-in-01" width="19" height="19" />
                            <span>Check-in</span>
                        </p>

                        <p>3:00 PM</p>
                    </div>

                    <div className="row">
                        <p>
                            <Icon icon="hugeicons:calendar-check-out-01" width="19" height="19" />
                            <span>Check-out</span>
                        </p>
                        <p>12:00 PM</p>
                    </div>
                </div>

                <div className="group">
                    <div className="row">
                        <p>
                            <Icon icon="iconoir:calendar" width="19" height="19" />
                            <span>Arrival</span>
                        </p>

                        <p>May 18, 2024</p>
                    </div>

                    <div className="row">
                        <p>
                            <Icon icon="iconoir:calendar" width="19" height="19" />
                            <span>Departure</span>
                        </p>

                        <p>May 18, 2024</p>
                    </div>
                </div>

                <div className="group">
                    <div className="row">
                        <p>
                            <Icon icon="ion:person-outline" width="19" height="19" />
                            <span>Adults</span>
                        </p>

                        <p>2x</p>
                    </div>

                    <div className="row">
                        <p>
                            <Icon icon="ion:person-outline" width="19" height="19" />
                            <span>Childrens</span>
                        </p>

                        <p>2x</p>
                    </div>
                </div>
                
                <div className="group">
                    <div className="row">
                        <p>Family Apartment</p>
                        <p>96,53€</p>
                    </div>  

                    <div className="row">
                        <p>Breakfast</p>
                        <p>26,53€</p>
                    </div>  

                    <div className="row">
                        <p>Taxes and Fees</p>
                        <p>16,53€</p>
                    </div>
                </div>

                <div className="group">
                    <div className="row">
                        <p>TOTAL (fees and taxes included)</p>
                        <p className="price">296,53€</p>
                    </div>  
                </div>

                <button>Continue</button>
            </div>
        </section>
    )
}