import RoomPicture from "../../../../assets/pictures/Room Showcase.png";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { RoomData } from "./RoomsData";
import "./Rooms.scss";

const imageProp = {
    viewport: { once: true },
    transition: { duration: 1 },
    initial: { opacity: 0, scale: 0 },
    whileInView: { opacity: 1, scale: 1 },
}

export default function Rooms() {

    const { t } = useTranslation();

    return (
        <div id="rooms">
            <h2 className="title">{t("Home.Rooms.Title")}</h2>

            <div className="room-showcase-container">
                {RoomData.map((item, i) => <RoomTab item={item} key={i} />)}                
            </div>
        </div>
    )
}

function RoomTab({ item }) {
    return (
        <motion.a
            href="#"
            initial={imageProp.initial}
            viewport={imageProp.viewport}
            transition={imageProp.transition}
            whileInView={imageProp.whileInView}
            style={{ backgroundImage: `url('${RoomPicture}` }}
        >
            <div>Yes</div>
        </motion.a>
    )
}