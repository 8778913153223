import BabyStroller from "../../../../assets/icons/activity/baby-stroller.svg";
import Volleyball from "../../../../assets/icons/activity/volleyball.svg";
import Jacuzzi from "../../../../assets/icons/activity/jacuzzi.svg";
import Castle from "../../../../assets/icons/activity/castle.svg";
import Sunset from "../../../../assets/icons/activity/sunset.svg";
import Canoe from "../../../../assets/icons/activity/canoe.svg";
import Boat from "../../../../assets/icons/activity/boat.svg";
import Yoga from "../../../../assets/icons/activity/yoga.svg";
import Boy from "../../../../assets/icons/activity/boy.svg";
import Gym from "../../../../assets/icons/activity/gym.svg";

export const ActivityData = [
    {
        icon: Boat,
        label: "Boat",
        link: "/",
    },
    {
        icon: Sunset,
        label: "Sunset",
        link: "/",
    },
    {
        icon: Castle,
        label: "Castle",
        link: "/",
    },
    {
        icon: Canoe,
        label: "Canoe",
        link: "/",
    },
    {
        icon: Jacuzzi,
        label: "Jacuzzi",
        link: "/",
    },
    {
        icon: Boy,
        label: "Boy",
        link: "/",
    },
    {
        icon: Volleyball,
        label: "Volleyball",
        link: "/",
    },
    {
        icon: BabyStroller,
        label: "BabyStroller",
        link: "/",
    },
    {
        icon: Yoga,
        label: "Yoga",
        link: "/",
    },
    {
        icon: Gym,
        label: "Gym",
        link: "/",
    },
]