import { Icon } from '@iconify/react/dist/iconify.js';
import { useEffect, useState } from 'react';
import "./LanguageSelector.scss";
import i18n from '../../i18n';

const localVariable = "lang";

const languages = [
    { id: 1, name: "Czech", short: "cz"},
    { id: 2, name: "English", short: "en"},
    { id: 3, name: "Tunisian", short: "ts"},
]

export default function LanguageSelector() {

    const [isOpen, setIsOpen] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem(localVariable) || "en");

    const chooseLanguage = (lngValue) => {
        setIsOpen(false)
        i18n.changeLanguage(lngValue);
        setSelectedLanguage(lngValue);
        localStorage.setItem(localVariable, lngValue);
    }

    useEffect(() => {
        const localLanguage = localStorage.getItem(localVariable)
        if (!localLanguage) {
            localStorage.setItem(localVariable, selectedLanguage);
        } else if (localLanguage !== selectedLanguage) {
            setSelectedLanguage(localLanguage);
            i18n.changeLanguage(localLanguage);
        }
    }, [selectedLanguage])

    return (
        <div className='language-selector'>
            <button className='font-jost' onClick={() => setIsOpen(!isOpen)}>
                <span>{languages.find(x => x.short === selectedLanguage).name ?? 'N/A'}</span>
                <Icon icon={isOpen ? "eva:arrow-left-fill" : "eva:arrow-right-fill"} width="19" height="19" />
            </button>

            {isOpen && languages.map(({id, name, short}) => (
                short === selectedLanguage ? null : (
                    <ChildButton 
                        onClick={chooseLanguage} 
                        short={short}
                        text={name} 
                        key={id}
                    />
                )
            ))}
        </div>
    );
};

function ChildButton({ onClick, text, short }) {
    return <button className='font-jost' onClick={() => onClick(short)}>{text}</button>
}