// Packages
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import React, { Suspense } from 'react';
import './index.scss';
import './i18n';

// Styles
import "./assets/styles/Containers.scss";

// Components
import Body from './layouts/Body/Body';

// Pages
import Home from './pages/Home/Home';
import Blog from './pages/Blog/Blog';
import Contact from './pages/Contact/Contact';
import Reservation from './pages/Reservation/Reservation';
import NotFound from './pages/NotFound/NotFound';
import Faq from './pages/Faq/Faq';

// Website
const router = createBrowserRouter([
  {
    path: "/",
    element: <Body />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "reservation",
        element: <Reservation />,
      },
      {
        path: "blog",
        element: <Blog />,
      },
      {
        path: "contact",
        element: <Contact />,
      },
      {
        path: "faq",
        element: <Faq />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <Suspense fallback={<div>Loading....</div>}>
    <RouterProvider router={router} />
  </Suspense>
);