import "./Loader.scss";

export default function Loader({ isFullSized }) {
    
    const loaderSpan =  <span id="loader" />;
    
    return !isFullSized ? loaderSpan : (
        <section>
            <div className="loader-filter">
                {loaderSpan}
            </div> 
        </section>
    )
}