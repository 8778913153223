import ShowcasePhoto from "../../../../assets/pictures/Showcase/Showcase.png";
import { motion } from "framer-motion"
import "./Showcase.scss";
import { useTranslation } from "react-i18next";

export default function Showcase() {

    const { t } = useTranslation();

    const imageProp = {
        viewport: { once: true },
        transition: { duration: 1 },
        initial: { opacity: 0, scale: 0 },
        whileInView: { opacity: 1, scale: 1 },
    }

    return (
        <div id="showcase">
            <h2 className="title">{t("Home.Showcase.Title")}</h2>
            <p className="title-text">{t("Home.Showcase.Title-Text")}</p>

            <div className="images">
                <motion.div
                    viewport={imageProp.viewport}
                    transition={imageProp.transition}
                    initial={imageProp.initial}
                    whileInView={imageProp.whileInView}
                >
                    <img src={ShowcasePhoto} alt="showcase 1"/>
                </motion.div>

                <motion.div
                    viewport={imageProp.viewport}
                    transition={imageProp.transition}
                    initial={imageProp.initial}
                    whileInView={imageProp.whileInView}
                >
                    <img src={ShowcasePhoto} alt="showcase 2" className="bigger"/>
                </motion.div>

                <motion.div
                    viewport={imageProp.viewport}
                    transition={imageProp.transition}
                    initial={imageProp.initial}
                    whileInView={imageProp.whileInView}
                >
                    <img src={ShowcasePhoto} alt="showcase 3"/>
                </motion.div>
            </div>

            <h1 className="background-text">Wild Serenity Resort</h1>

            <div className="second-text">
                <h1>Wild Serenity</h1>
                <p className="title-text">{t("Home.Showcase.Second-Text")}</p>
            </div>
        </div>
    )
}