import { Icon } from "@iconify/react/dist/iconify.js";
import "./HeroSection.scss";

export default function HeroSection({ picture, words }) {
    return (
        <div className="hero-section">
            <div className="image" style={{ backgroundImage: `url(${picture})` }} />

            <div className="text">
                {words?.map((word, i) => <h1 key={i}>{word}</h1>)}

                <a href="#next" className="scroll-down-container">
                    <Icon icon="iconoir:nav-arrow-down" width="42" height="42" />
                    <Icon icon="iconoir:nav-arrow-down" width="42" height="42" />
                    <Icon icon="iconoir:nav-arrow-down" width="42" height="42" />
                </a>
            </div>
        </div>
    )
}