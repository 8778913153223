import HotelLogo from "../../../assets/pictures/Logos/Hotel-Icon.png";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import "./Footer.scss";

const links = [
    { id: 1, title: "Home", to: "/" },
    { id: 2, title: "Rooms", to: "/rooms" },
    { id: 3, title: "Gallery", to: "/gallery" },
    { id: 4, title: "Reservation", to: "/reservation" },
    { id: 5, title: "FAQ", to: "/faq" },
    { id: 6, title: "Contact", to: "/contact" },
]

export default function Footer() {

    const { t } = useTranslation();

    const year = new Date().getFullYear();

    return (
        <footer>
            <div className="top">
                <div>
                    <h4>{t("Footer.About.Title")}</h4>
                    <p>{t("Footer.About.Text")}</p>
                </div>

                <div>
                    <h4>{t("Footer.Contact.Title")}</h4>
                    <div className="flex-column">
                        <a href="https://maps.app.goo.gl/hiMJdfxnYWHAMiuN6" target="_blank" rel="noreferrer">
                            <Icon icon="ph:map-pin" />
                            <span>Maaloula, Tunisia</span>
                        </a>

                        <a href="mailto:someone@example.com">
                            <Icon icon="mi:email" />
                            <span>example@gmail.com</span>
                        </a>
                        
                        <a href="tel:+4733378901">
                            <Icon icon="ph:phone" />
                            <span>+418 000 000 000</span>
                        </a>
                    </div>
                </div>

                <div>
                    <h4>{t("Footer.Links.Title")}</h4>
                    <div className="links">
                        {links.map(({ id, title, to }) => (
                            <Link key={id} to={to}>
                                {t("Footer.Links.Items." + title)}
                            </Link>
                        ))}
                    </div>
                </div>

                <div>
                    <h4>{t("Footer.Newsletter.Title")}</h4>
                    <p>{t("Footer.Newsletter.Text")}</p>
                </div>
            </div>

            <div className="bottom">
                <div className="flex-start">
                    <p>Copyright © {year} by Inowiz</p>
                </div>

                <Link to={'/'} className="logo">
                    <img src={HotelLogo} alt="" />
                </Link>

                <div className="links flex-end">
                    <a href="https://creativelayers.net/themes/swiss-resort-html/index.html#" target="_blank" rel="noreferrer">
                        <Icon icon="bi:twitter" width="18" height="18" />
                    </a>

                    <a href="https://www.instagram.com/wildserenityresort" target="_blank" rel="noreferrer">
                        <Icon icon="bi:instagram" width="18" height="18" />
                    </a>
                    
                    <a href="https://el.commonsupport.com/newwp/hotera/resort-hotel/" target="_blank" rel="noreferrer">
                        <Icon icon="fontisto:facebook" width="18" height="18" />
                    </a>
                </div>
            </div>
        </footer>
    )
}