import { useTranslation } from "react-i18next";
import { ActivityData } from "./ActivityData";
import { Link } from "react-router-dom";
import "./Activity.scss";

export default function Activity() {

    const { t } = useTranslation()

    return (
        <div id="activity">
            <h2 className="title">{t("Home.Activities.Title")}</h2>
            <p className="title-text">{t("Home.Activities.Title-Text")}</p>

            <div className="activity-container">
                {ActivityData.map((act, i) => <ActivityTab key={i} activity={act} t={t} />)}
            </div>
        </div>
    )
}

function ActivityTab({ activity, t }) {
    return (
        <Link to={activity.link} className="tab">
            <img src={activity.icon} alt={'activity'} />
            <h4>{t("Home.Activities.Tabs." + activity.label)}</h4>
        </Link>
    )
}