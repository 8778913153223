import Post from "../../../assets/pictures/instagram-post.png";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import "./Instagram.scss";

export default function Instagram() {

    const { t } = useTranslation()

    const imageProp = {
        viewport: { once: true },
        transition: { duration: 1 },
        initial: { opacity: 0, scale: 0 },
        whileInView: { opacity: 1, scale: 1 },
    }

    return (
        <div id="instagram">
            <p className="profile">@wildserenityresort</p>
            <h2 className="title">{t("Home.Instagram.Title")}</h2>
            <p className="title-text">{t("Home.Instagram.Text")}</p>

            <div className="post-container">
                <motion.a
                    href="https://www.instagram.com/wildserenityresort"
                    target="_blank"
                    initial={imageProp.initial}
                    viewport={imageProp.viewport}
                    transition={imageProp.transition}
                    whileInView={imageProp.whileInView}
                >
                    <img src={Post} alt="showcase 1"/>
                </motion.a>
                <motion.a
                    href="https://www.instagram.com/wildserenityresort"
                    target="_blank"
                    initial={imageProp.initial}
                    viewport={imageProp.viewport}
                    transition={imageProp.transition}
                    whileInView={imageProp.whileInView}
                >
                    <img src={Post} alt="showcase 1"/>
                </motion.a>
                <motion.a
                    href="https://www.instagram.com/wildserenityresort"
                    target="_blank"
                    initial={imageProp.initial}
                    viewport={imageProp.viewport}
                    transition={imageProp.transition}
                    whileInView={imageProp.whileInView}
                >
                    <img src={Post} alt="showcase 1"/>
                </motion.a>
                <motion.a
                    href="https://www.instagram.com/wildserenityresort"
                    target="_blank"
                    initial={imageProp.initial}
                    viewport={imageProp.viewport}
                    transition={imageProp.transition}
                    whileInView={imageProp.whileInView}
                >
                    <img src={Post} alt="showcase 1"/>
                </motion.a>
                <motion.a
                    href="https://www.instagram.com/wildserenityresort"
                    target="_blank"
                    initial={imageProp.initial}
                    viewport={imageProp.viewport}
                    transition={imageProp.transition}
                    whileInView={imageProp.whileInView}
                >
                    <img src={Post} alt="showcase 1"/>
                </motion.a>
            </div>
        </div>
    )
}