import DummyHeader from "../../components/views/DummyHeader/DummyHeader";
import Sunbath from "../../assets/pictures/Sunbath.svg";
import { Link } from "react-router-dom";
import "./NotFound.scss";

export default function NotFound() {
    return (
        <>
            <DummyHeader />
            <section className="not-found">
                <div className="not-found-container">
                    <img src={Sunbath} alt="guy who sunbath" />

                    <div className="not-found-text">
                        <h3 className="subtitle">Oops... Page Not Found</h3>
                        <h2 className="title">Take a break in our resort!</h2>
                        <p>Click <Link to={'/'}>here</Link> to go home</p>
                    </div>
                </div>
            </section>
        </>
    )
}