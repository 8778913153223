import HotelLogo from "../../../assets/pictures/Logos/Hotel-Icon.png";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useTranslation } from "react-i18next";
import AsideMenu from "../Aside/AsideMenu";
import { useCycle } from "framer-motion";
import { Link } from "react-router-dom";
import "./Header.scss";

export default function Header() {
    // const breakPoint = 600;

    // const [isScrolled, setIsScrolled] = useState(false);
    // const [useDesktopHeader, setUseDesktopHeader] = useState(window.innerWidth > breakPoint);

    // const handleScroll = () => setIsScrolled(window.scrollY > window.innerHeight * 0.9 - 84);

    // useEffect(() => setUseDesktopHeader(window.innerWidth > breakPoint), []);

    // useEffect(() => {
    //     window.addEventListener("scroll", handleScroll);
    //     return () => {
    //         window.removeEventListener("scroll", handleScroll);
    //     };
    // }, []);

    //const headerClass = isScrolled ? "header-scrolled" : "header";

    const { t } = useTranslation();
    const [open, cycleOpen] = useCycle(false, true);

    return (
        <>
            <header className="header-scrolled">
                <div className="flex-start">
                    <button onClick={cycleOpen} className="menu-btn">
                        <Icon icon="ion:menu" width="32" height="32" />
                    </button>
                </div>

                <Link to={"/"} className="logo">
                    <img src={HotelLogo} alt="" />
                </Link>

                <div className="flex-end">
                    <Link to={"/reservation"} className="res-btn">
                        {t("Header.ButtonText")}
                    </Link>
                </div>
            </header>

            {open && (
                <AsideMenu open={open} onClose={cycleOpen} />
            )}
        </>
    );
}