import { initReactI18next } from "react-i18next";
import i18n from "i18next";

// Languages
import translationsInEnglish from "../locales/en/translation.json";
import translationsInCzech from "../locales/cz/translation.json";
import translationsInTunis from "../locales/ts/translation.json";

const resources = {
    en: {
        translation: translationsInEnglish,
    },
    cz: {
        translation: translationsInCzech,
    },
    ts: {
        translation: translationsInTunis,
    },
};

const language = localStorage.getItem("lang") || "en";

i18n
.use(initReactI18next)
.init({
    resources,
    lng: language,
    debug: true,
    fallbackLng: "en",
    interpolation: {
        escapeValue: false,
    },
    ns: "translation",
    defaultNS: "translation",
});

export default i18n;