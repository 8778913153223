import HeroSectionPhoto from "../../assets/pictures/Aside-Background.jpg";
import HeroSection from "../../components/views/HeroSection/HeroSection";
import { useState } from "react";
import "./Contact.scss"
import Loader from "../../components/loader/Loader";
import { Icon } from "@iconify/react/dist/iconify.js";
import Instagram from "../../components/views/Instagram/Instagram";

export default function Contact() {
    return (
        <section id="contact">
            <HeroSection picture={HeroSectionPhoto} words={["Contact"]} />

            <div id="next">
                <div className="emails-container">
                    <p>reservation@gmail.com</p>
                    <p>reservation@gmail.com</p>
                    <p>reservation@gmail.com</p>
                </div>

                <div className="map-form-container">
                    <GoogleMap />
                    <EmailForm />
                </div>
            </div>

            <Instagram />
        </section>
    )
}

function GoogleMap() {
    return (
        <div className="grid-center">
            <Loader />
        </div>
    )
}

function EmailForm() {

    const [message, setMessage] = useState(null)
    const [formData, setFormData] = useState({})

    function handleChange(e) {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value})
    }

    return (
        <form className="contact">
            <div className="row">
                <label>
                    <span>First name <span className="star">*</span></span>
                    <input type="text" onChange={handleChange} name="firstname" placeholder="Enter name" required />
                </label>

                <label>
                    <span>Last name <span className="star">*</span></span>
                    <input type="text" onChange={handleChange} name="lastname" placeholder="Enter surname" required />
                </label>
            </div>

            <label>
                <span>Email <span className="star">*</span></span>
                <input type="email" onChange={handleChange} name="email" placeholder="Enter email" required />
            </label>

            <label>
                <span>Subject <span className="star">*</span></span>
                <input type="text" onChange={handleChange} name="subject" placeholder="Enter subject" required />
            </label>

            <label>
                <span>Message <span className="star">*</span></span>
                <textarea onChange={handleChange} name="message" placeholder="Write your message" required />
            </label>

            <div className={message === null ? "alert-container" : "alert-container success"}>
                <Icon icon="ph:info-bold" width="19" height="19" />
                <p>{message && message}</p>
            </div>

            <div className={message === null ? "alert-container" : "alert-container error"}>
                <Icon icon="ph:warning-circle-bold" width="19" height="19" />
                <p>{message && message}</p>
            </div>

            {true 
            ? <button className="btn">Send Message</button>
            : (
                <button className="btn loader">
                    <span />
                </button>
            )}
        </form>
    )
}

function AlertMessage({ type, message }) {

    return (
        <div className={message === null ? "alert-container" : "alert-container error"}>
            <Icon icon="ph:warning-circle-bold" width="19" height="19" />
            <p>{message && message}</p>
        </div>
    )
}