import Footer from "../../components/views/Footer/Footer";
import Header from "../../components/views/Header/Header";
import { Outlet, useLocation } from "react-router-dom";
import { useEffect } from "react";

export default function Body() {

    const location = useLocation();

    useEffect(() => {
        return () => window.scrollTo(0, 0)
    }, [location.pathname])

    return (
        <>
            <Header />
            <Outlet />
            <Footer />
        </>
    )
}