import LanguageSelector from "../../languageSelector/LanguageSelector";
import HotelLogo from "../../../assets/pictures/Logos/Hotel-Logo.png";
import { AnimatePresence, motion } from "framer-motion";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "./AsideMenu.scss";

export default function AsideMenu({ open, onClose }) {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const sideVariants = {
        closed: {
            transition: {
                staggerChildren: 0.2,
                staggerDirection: -1,
            },
        },
        open: {
            transition: {
                staggerChildren: 0.2,
                staggerDirection: 1,
            },
        },
    };

    const itemVariants = {
        closed: { opacity: 0 },
        open: { opacity: 1 },
    };

    const links = [
        { id: 1, name: "Home", to: "/" },
        { id: 1, name: "Blog", to: "/blog" },
        { id: 2, name: "Rooms", to: "/rooms" },
        { id: 3, name: "Pricing", to: "/pricing" },
        { id: 4, name: "Gallery", to: "/gallery" },
        { id: 5, name: "Services", to: "/services" },
        { id: 6, name: "Restaurant", link: "https://getcssscan.com/css-box-shadow-examples" },
        { id: 7, name: "Reservation", to: "/reservation" },
        { id: 8, name: "FAQ", to: "/faq" },
        { id: 8, name: "Carrer", to: "/carrer" },
        { id: 9, name: "Contact", to: "/contact" },
    ];

    function onItemClick(to) {
        onClose()
        navigate(to)
    }

    return (
        <AnimatePresence>
            {open && (
                <motion.aside
                    initial={{ width: 0, height: '100vh' }}
                    animate={{ width: '100vw' }}
                    exit={{
                        width: 0,
                        transition: { delay: 0.7, duration: 0.3 }
                    }}
                >
                    <motion.div
                        className="container"
                        initial="closed"
                        animate="open"
                        exit="closed"
                        variants={sideVariants}
                    >
                        <button onClick={onClose} className="close">
                            <Icon icon="ion:close" width="24" height="24" />
                            <span>Close</span>
                        </button>
                        <nav>
                            {links.map(({ name, to, id }) => (
                                <motion.div
                                    key={id}
                                    variants={itemVariants}
                                >
                                    <button onClick={() => onItemClick(to)} className="font-cormorant">{t("Header.Navigation." + name)}</button>
                                </motion.div>
                            ))}
                        </nav>
                        <div className="image" />
                        <LanguageSelector />
                    </motion.div>

                    {open && (
                        <motion.div>
                            <div className="row">
                                <img src={HotelLogo} alt="Hotel Logo" />
                            </div>

                            <div className="row">
                                <h1>Wild Serenity Resort</h1>
                                <p>{t("Header.Aside.Text")}</p>
                            </div>

                            <div className="row">
                                <h2>{t("Header.Aside.Location")}</h2>
                                <p>PO Box 16122 Collins Street West</p>
                                <p>Victoria 8007 Australia</p>
                            </div>

                            <div className="row">
                                <h2>{t("Header.Aside.Contact")}</h2>
                                <p>+420 000 000 000</p>
                                <p>+420 000 000 000</p>
                            </div>
                        </motion.div>
                    )}
    
                </motion.aside>
            )}
        </AnimatePresence>
    );
}