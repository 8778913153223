import Instagram from "../../components/views/Instagram/Instagram";
import { Icon } from "@iconify/react/dist/iconify.js";
import Activity from "./views/activity/Activity";
import Showcase from "./views/showcase/Showcase";
import Rooms from "./views/rooms/Rooms";
import "./Home.scss";

export default function Home() {
    return (
        <section className="home">
            <div className="banner">
                <div className="text">
                    <div className="container">
                        <div className="hr-line" />
                        <h1 className="font-murecho">Wild</h1>
                        <div className="hr-line" />
                    </div>

                    <h1 className="font-murecho mb-40">Serenity</h1>
                </div>

                <a href="#showcase" className="scroll-down-container">
                    <Icon icon="iconoir:nav-arrow-down" width="42" height="42" />
                    <Icon icon="iconoir:nav-arrow-down" width="42" height="42" />
                    <Icon icon="iconoir:nav-arrow-down" width="42" height="42" />
                </a>
            </div>

            <Showcase />

            <Rooms />

            <Activity />

            <Instagram />
        </section>
    )
}